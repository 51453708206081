<template>
  <span>
    <v-navigation-drawer v-model="drawer" v-bind="ToolbarIsMobile" app>
      <template v-slot:prepend>
        <v-img :aspect-ratio="23/8" src="../../../assets/material.jpg">
          <v-row align="center" class="lightbox white--text pa-2 fill-height">
            <v-col>
              <v-list-item>
                <v-list-item-avatar class="my-auto">
                  <img src="../../../assets/user_avatar.png" />
                </v-list-item-avatar>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="white--text">
                  <v-list-item-title>{{getInfoUsuario.nombre ? getInfoUsuario.nombre :"..."}} {{getInfoUsuario.apellidos ? getInfoUsuario.apellidos :"..."}}</v-list-item-title>
                  <v-list-item-subtitle class="white--text d-flex align-center">
                    <v-icon size="15" color="white" class="mx-1">mdi-home</v-icon>
                    {{getInfoUsuario.rol ? getInfoUsuario.rol : "..."}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-img>
      </template>
      <v-list nav>
        <v-list-item-group :value="getMenuIndex">
          <v-list-item
            v-for="(item, index) in navItemsAdmin"
            :key="index"
            :to="{ name: item.referenceName }"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title text>{{ item.nameAdmin }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block dark color="teal" @click="logoutAdmin">Cerrar Sesión</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar color="teal darken-1" app fixed>
      <v-app-bar-nav-icon color="white" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <p class="headline white--text my-auto">{{ navItemsAdmin[getMenuIndex].nameAdmin }}</p>
      <v-spacer></v-spacer>
      <v-btn :to="{ name: 'Ventas al Crédito' }" icon dark>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="250"
        offset-y
        origin="center center"
        :nudge-bottom="10"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon text v-on="on">
            <v-badge color="teal" overlap>
              <span
                slot="badge"
                v-if="getListaAnotaciones.length > 0"
              >{{ getListaAnotaciones.length }}</span>
              <v-icon medium color="white">mdi-notebook-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-toolbar flat dense>
          <v-toolbar-title>
            <h5>
              Tienes {{ getListaAnotaciones.length }} {{getListaAnotaciones.length == 1 ? 'anotación' :'anotaciones'}}
              <v-btn :disabled="isLoading" icon text color="success" @click="fetchAnotaciones">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-list two-line class="pa-0">
          <template v-for="(item, index) in getListaAnotaciones">
            <v-divider :key="`${index}-div`" />
            <v-list-item :key="item.idAnotacion">
              <v-list-item-avatar dense color="green" class="mr-2">
                <v-icon dark size="24">mdi-bell</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle v-text="item.textoAnotacion" style="white-space: pre-line;"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="caption">
                {{
                $moment(parseInt(item.fechaAnotacion)).fromNow()
                }}
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-btn
            block
            color="base"
            dark
            :to="{ name: 'Anotaciones' }"
            @click="menu = false"
          >Ver Anotaciones</v-btn>
        </v-list>
        <v-divider></v-divider>
      </v-menu>
    </v-app-bar>
  </span>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      drawer: false,
      selectedIndex: 0,
      selectedAdminIndex: 0,
      menu: false,
      rating: null,
      dialog: true,
      dialogSettings: false,
      switchEmailNotification: true,
      showPassword: null,
      showPasswordConfirm: null,
      userEmail: null,
      password: null,
      passwordConfirm: null,
      error: false,
      showResult: false,
      result: '',
      items: [],
      navItemsAdmin: [
        {
          nameAdmin: 'Inicio',
          referenceName: 'Inicio Admin',
          icon: 'mdi-home'
        },
        {
          nameAdmin: 'Gestión de Movimientos',
          referenceName: 'Gestion Movimientos Admin',
          icon: 'mdi-cart-arrow-right'
        },
        {
          nameAdmin: 'Gestión de Cobranzas',
          referenceName: 'Gestion Cobranzas Admin',
          icon: 'mdi-cash-multiple'
        },
        {
          nameAdmin: 'Gestión de Personas',
          referenceName: 'Gestion Personas Admin',
          icon: 'mdi-account-group'
        },
        {
          nameAdmin: 'Gestión de Clientes',
          referenceName: 'Gestion Clientes Admin',
          icon: 'mdi-briefcase-account'
        },
        {
          nameAdmin: 'Gestión de Tiendas',
          referenceName: 'Gestion Tiendas Admin',
          icon: 'mdi-store'
        },
        {
          nameAdmin: 'Gestión de Productos',
          referenceName: 'Gestion Productos Admin',
          icon: 'mdi-cart'
        }
      ]
    }
  },

  methods: {
    ...mapActions('auth', ['logoutAdmin', 'logoutUsuario']),
    ...mapActions('user', ['fetchInfoUsuario', 'fetchListaProductosClientes']),
    ...mapActions('tienda', ['fetchListaTiendas']),
    ...mapActions('trabajador', ['fetchAnotaciones'])
  },
  computed: {
    ToolbarIsMobile() {
      const binding = {}
      if (this.$vuetify.breakpoint.smAndDown) {
        binding.temporary = true
      } else {
        binding.fixed = true
        this.drawer = true
      }
      return binding
    },

    getMenuIndex() {
      return this.$route.meta.menuIndex
    },

    ...mapGetters('user', ['getInfoUsuario', 'isLoading']),
    ...mapGetters('trabajador', ['getListaAnotaciones'])
  },
  async mounted() {
    await this.fetchInfoUsuario()
    await this.fetchListaProductosClientes()
    await this.fetchListaTiendas()
    await this.fetchAnotaciones()
    if (this.getInfoUsuario.rol != 'Administrador') {
      this.logoutUsuario()
    }
  }
}
</script>
